<template>
  <div class="page">
    <swiper :direction="'vertical'">
      <swiper-slide><Slide1 /></swiper-slide>
      <swiper-slide><Slide2 /></swiper-slide>
      <swiper-slide><Slide3 /></swiper-slide>
      <swiper-slide><Slide4 /></swiper-slide>
      <swiper-slide><Slide5 /></swiper-slide>
      <swiper-slide><Slide6 /></swiper-slide>
      <swiper-slide><Slide7 /></swiper-slide>
      <swiper-slide><Slide8 /></swiper-slide>
    </swiper>
  </div>
</template>
<script>
  import { Swiper, SwiperSlide } from 'swiper/vue';
  import Slide1 from './Slide1';
  import Slide2 from './Slide2';
  import Slide3 from './Slide3';
  import Slide4 from './Slide4';
  import Slide5 from './Slide5';
  import Slide6 from './Slide6';
  import Slide7 from './Slide7';
  import Slide8 from './Slide8';
  import 'swiper/css';
  // import 'swiper/css/navigation';
  // import 'swiper/css/pagination';

  // Import Swiper styles
  export default {
    name: 'Mobile',
    components: {
      Swiper,
      SwiperSlide,
      Slide1,
      Slide2,
      Slide3,
      Slide4,
      Slide5,
      Slide6,
      Slide7,
      Slide8
    },
  };
</script>
<style>
  html,body {
    width: 100%;
    height: 100%;
    font-family: Yu Gothic UI;
  }
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
 .page, #app {
    width: 100%;
    height: 100%;
 }
 .swiper {
  width: 100%;
  height: 100%;
}
.bullet {
  display: inline-block;
  width: 11px;
  height: 11px;
  background-color: #43464A;
  border-radius: 100%;
  opacity: 0.2;
  margin: auto 7px;
}
.bullet-active {
  opacity: 0.5;
}

.bullet-light {
  display: inline-block;
  width: 11px;
  height: 11px;
  background-color: #FFFFFF;
  border-radius: 100%;
  opacity: 0.2;
  margin: auto 7px;
}
.bullet-light-active {
  opacity: 0.5;
}
.slide {
  overflow: hidden;
}
@-webkit-keyframes down-ct {
 from {
   transform: translateY(0);
   opacity: 1;
 }
 to {
   transform: translateY(10px);
   opacity: 0.5;
 }
}
.down-bar {
  width: 21px;
  height: 14px;
  flex: 0 0 14px;
  margin-bottom: 25px;
  transform: translateY(0);
  animation: down-ct 1.5s infinite;
}
</style>