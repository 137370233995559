<template>
  <div class="slide">
    <div class="line1">新旅游新业态</div>
    <div class="line2">
      <p>从数字化</p>
      <p>内容营销开始</p>
    </div>
    <div class="line3">NEW TOURISM AND NEW FORMATS</div>
    <div class="line4">START WITH DIGITAL CONTENT MARKETING</div>
    <div class="img-box">
      <div class="item">
        <img src="../assets/mobile/images/mp.png" class="img1">
        <div class="scan">扫描二维码</div>
        <div class="into">进入小程序</div>
      </div>
      <div class="line-box">
        <div class="line" />
        <div class="word">or</div>
        <div class="line" />
      </div>
      <div class="item">
        <img src="../assets/mobile/images/wx.png" class="img1">
        <div class="scan">扫描二维码</div>
        <div class="into">合作微信</div>
      </div>
    </div>
    <div class="site-footer">
        <a href="https://beian.miit.gov.cn/" target="_blank">京ICP备2020044986号-2</a>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'Slide8',
  }
</script>
<style scoped>
.slide {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: #FFFFFF;
  background-color: #414141;
}

.line1 {
  text-align: center;
  font-size: 48px;
  line-height: 56px;
  font-weight: 400;
}

.line2 {
  text-align: center;
  font-weight: 700;
  font-size: 48px;
  line-height: 56px;
  text-align: center;
  color: #6293EF;
  margin-bottom: 11px;
}

.line3 {
  font-family: Work Sans;
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 24px;
  text-transform: uppercase;
  color: #81868D;
  text-align: center;
}

.line4 {
  font-family: Work Sans;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  text-transform: uppercase;
  color: #81868D;
  text-align: center;
}
.img-box {
  display: flex;
  align-items: center;
  margin-top: 54px;
}

.img-box .line-box {
  flex: 0 0 14px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: #54585D;
}
.img-box .line-box .word {
  font-weight: 300;
  font-size: 14px;
  line-height: 22px;
  margin: 4px auto;
}
.img-box .line-box .line {
  width: 1px;
  height: 49px;
  background-color: #54585D;
}

.img-box .item {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.img-box .item .scan {
  font-size: 12px;
  line-height: 22px;
  color: #3866BC;
}

.img-box .item .into {
  font-size: 18px;
  line-height: 22px;
  font-weight: 700;
  color: #fff;
}

.img1 {
  width: 150px;
  height: 150px;
}
.site-footer {
  margin-top: 40px;
  text-align: center;
}
.site-footer a {
  color: #fff;
}

</style>
