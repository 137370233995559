<template>
  <div class="slide1">
    <div class="logo-box">
      <img class="logo" src="../assets/mobile/images/logo.png" alt="多米诺">
      <div>新旅游·新业态</div>
    </div>
    <div class="cont-box">
      <div class="line1">专为旅游行业提供</div>
      <div class="line2">数字化内容</div>
      <div class="line2">营销服务</div>
      <img class="img1" src="../assets/mobile/images/s1.png">
      <div class="line3">
        {{"Products | channels | connections".toUpperCase()}}
      </div>
    </div>
    <img src="../assets/mobile/images/1360.png" class="down-bar"/>
  </div>
</template>

<script>

  export default {
    name: 'Slide1',
    setup() {
      
    },
  }
</script>
<style scoped>
.slide1 {
  width: 100%;
  height: 100%;
  padding: 26px;
  flex-direction: column;
  color: #3866BC;
  background-color: #fff;
  text-align: center;
  background: #fff;
  display: flex;
}
.slide1 .logo-box {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 56px;
  letter-spacing: 0.5em;
  text-transform: uppercase;

}
.slide1 .logo {
  width: 52px;
  height: 52px;
  margin-right: 14px;
}
.slide1 .cont-box {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.slide1 .cont-box .line1 {
  font-family: PingFang SC;
  font-style: normal;
  font-weight: 100;
  font-size: 24px;
  line-height: 34px;
  margin-bottom: 12px;
}
.slide1 .cont-box .line2 {
  font-weight: bold;
  font-size: 48px;
  line-height: 55px;
}
.slide1 .cont-box .img1 {
  Width: 247.04px;
  Height: auto;
  margin: 27.31px auto 41px;
}
.slide1 .cont-box .line3 {
  font-family: Yaldevi Colombo Medium;
  font-weight: 500;
  font-size: 8Px;
  line-height: 30px;
  letter-spacing: 0.3em;
  opacity: 0.5;
}
.slide1 .down-bar {
  align-self: center;
  margin-bottom: 0
}
</style>
