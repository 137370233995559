import { createApp } from 'vue'
import App from './App.vue'
import 'lib-flexible/flexible.js';
import './rem.js';
import isMoblie from 'is-mobile'
if (isMoblie()) {
  createApp(App).mount('#app')
} else {
  window.location.href = '/pc/index.html'
}

