<template>
  <div class="slide">
    <swiper class="sub-swiper" :pagination="pagination" :autoplay="false">
      <swiper-slide>
        <div class="line1">丰富多样的内容形式</div>
        <div class="line2">
          <p>对应多样化</p>
          <p>的营销场景</p>
        </div>
        <div class="line3">短视频</div>
        <img src="../assets/mobile/images/s5-1.png" class="img1">
        
      </swiper-slide>
      <swiper-slide>
        <div class="line1">丰富多样的内容形式</div>
        <div class="line2">
          <p>对应多样化</p>
          <p>的营销场景</p>
        </div>
        <div class="line3">长图文</div>
        <img src="../assets/mobile/images/s5-2.png" class="img1">
        
      </swiper-slide>
      <swiper-slide>
        <div class="line1">丰富多样的内容形式</div>
        <div class="line2">
          <p>对应多样化</p>
          <p>的营销场景</p>
        </div>
        <div class="line3">短图文</div>
        <img src="../assets/mobile/images/s5-3.png" class="img1">
        
      </swiper-slide>
    </swiper>
    <img src="../assets/mobile/images/1360.png" class="down-bar"/>
  </div>
</template>

<script>
  import SwiperCore, { Pagination, Autoplay } from 'swiper';
  import { Swiper, SwiperSlide } from 'swiper/vue';
  import "swiper/css/pagination"
  import "swiper/css/autoplay"

  SwiperCore.use([Pagination, Autoplay])
  export default {
    name: 'Slide4',
    data() {
      return {
        pagination: {
          "clickable": true,
          bulletClass : 'bullet',
          bulletActiveClass: 'bullet-active'
        }
      }
    },
    components: {
      Swiper,
      SwiperSlide,
    },
    setup() {
      return {
        modules: [ Pagination, Autoplay ],
      }
      
    }
  }
</script>
<style scoped>
.slide {
  position: relative;
  width: 100%;
  height: 100%;
  background: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.sub-swiper {
  width: 100%;
  height: 100%;
}
.swiper-slide {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  color: #3866BC;
  flex-direction: column;
}

.line1 {
  font-size: 24px;
  line-height: 50px;
  font-weight: 400;
}

.line2 {
  font-weight: 700;
  font-size: 40px;
  line-height: 50px;
  text-align: center;
}

.step-box {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 44px 0 6px;
}

.step-box .step {
  flex: 0 0 99px;
  background: #F17E55;
  border-radius: 50px;
  font-weight: bold;
  font-size: 24px;
  line-height: 43px;
  color: #FFF;
  width: 99px;
  height: 43px;
  text-align: center;
}
.step-box .dot-box {
  flex: 2;
  display: flex;
  justify-content: space-between;
  padding: 0 12px;
}
.step-box .dot-box .dot {
  background: #FFAE92;
  width: 11px;
  height: 11px;
  border-radius: 100%;
}

.img1 {
  height: 326.31px;
  width: auto;
}

.line3 {
  font-weight: bold;
  font-size: 24px;
  line-height: 38px;
  color: #F17E55;
  margin: 53px auto 24px;
}
</style>
