<template>
  <div class="slide2">
    <img src="../assets/mobile/images/mt.png" class="img">
    <div class="slide">
      <div class="block1">
        你是否有 这样的问题 
      </div>
      <div class="block2">
        <p>旅行社不再被需要？</p>
        <p>没有存在价值？</p>
        <p>产品落后且单一？</p>
        <p>流量越来越少？</p>
        <p>成本越来越高？</p>
        <div class="no">NO!</div>
      </div>
      <!-- <iframe id="txvideo" frameborder="0" src="https://v.qq.com/txp/iframe/player.html?vid=b3273e4dfqf" allowFullScreen="true"></iframe> -->
      <iframe id="txvideo" frameborder="0" src="https://v.qq.com/txp/iframe/player.html?vid=g32737igvsq" allowFullScreen="true"></iframe>
      <!-- <div id="txvideo" /> -->
    </div>
    <img src="../assets/mobile/images/1361.png" class="down-bar"/>
  </div>
</template>

<script>
  import '../../public/txplayer'
  export default {
    name: 'Slide2',
    mounted() {
      // eslint-disable-next-line no-undef
      // const player = new Txplayer({
      //   containerId: 'txvideo',
      //   vid: 'r32733slbj6',
      //   width: '100%',
      //   height: '100%'
      // });
      // player.on('ready', () => {})
    }
  }
</script>
<style scoped>
.slide2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  background: #3866BC;
}
.slide {
  position: relative;
  flex: 1 1 auto;
  width: 100%;
}

.block1 {
  position: absolute;
  width: 200px;
  height: 80px;
  left: 141px;
  top: 64px;
  font-family: PingFang SC;
  font-size: 40px;
  line-height: 40px;
  color: #6293EF;
  z-index: 1;
}

.img {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 90%;
  width: auto;
  z-index: 2;
}

.block2 {
  position: absolute;
  width: 216px;
  height: 34px;
  left: 141px;
  top: 153px;
  font-family: PingFang SC;
  font-size: 24px;
  line-height: 34px;
  color: #FFFFFF;
  z-index: 3;
}
.block2 .no {
  font-family: PingFang SC;
  font-weight: 600;
  font-size: 96px;
  line-height: 100px;
  color: #FFAE92;
  z-index: 3;
}
#txvideo {
  position: absolute;
  width: 339px;
  height: 193px;
  bottom: 84px;
  left: 18px;
  background-color: #cccccc;
  z-index: 5;
}
</style>
