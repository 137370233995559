<template>
  <Mobile />
</template>

<script>
import Mobile from './components/Mobile.vue'

export default {
  name: 'App',
  components: {
    Mobile
  },
  
}
</script>

