<template>
  <div class="slide7">
    <div class="slide">
      <div class="line1">数字化店铺让供应商实现</div>
      <div class="line2">
        <p>数据驱动的</p>
        <p>经营模式</p>
      </div>
      <div class="img-box">
        <img src="../assets/mobile/images/s7.png" class="img1">
      </div>
    </div>
    <img src="../assets/mobile/images/1360.png" class="down-bar"/>
  </div>
</template>

<script>
  export default {
    name: 'Slide4',
  }
</script>
<style scoped>
.slide7 {
  width: 100%;
  height: 100%;
   background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.slide {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: #3866BC;
  flex: 1 1 auto;
}

.line1 {
  text-align: center;
  font-size: 24px;
  line-height: 50px;
  font-weight: 400;
  margin-top: 50px;
}

.line2 {
  text-align: center;
  font-weight: 700;
  font-size: 40px;
  line-height: 50px;
  text-align: center;
}

.img-box {
  flex: 0 0 auto;
  height: auto;
  width: 100%;
  margin-top: 50px;
}

.img1 {
  height: auto;
  width: 100%;
}

</style>
