<template>
  <div class="slide">
    <swiper class="sub-swiper" :pagination="pagination" :autoplay="false">
      <swiper-slide>
        <div class="line1">
          快速搭建
          <span>
            在线化工具
            <img src="../assets/mobile/images/xcx.png" class="tip"/>
          </span>
        </div>
        <div class="line2">
          <p>助你实现</p>
          <p>线上化转型</p>
        </div>
        <div class="step-box">
          <div class="dot-box" style="visibility: hidden;">
            <div class="dot" v-for="i in 7" :key="i"/>
          </div>
          <div class="step">step1</div>
          <div class="dot-box">
            <div class="dot" v-for="i in 7" :key="i"/>
          </div>
        </div>
        <img src="../assets/mobile/images/s3-1.png" class="img1">
        <div class="line3">授权小程序到多米诺平台</div>
      </swiper-slide>
      <swiper-slide>
        <div class="line1">
          快速搭建
          <span>
            在线化工具
            <img src="../assets/mobile/images/xcx.png" class="tip"/>
          </span>
        </div>
        <div class="line2">
          <p>助你实现</p>
          <p>线上化转型</p>
        </div>
        <div class="step-box">
          <div class="dot-box">
            <div class="dot" v-for="i in 7" :key="i"/>
          </div>
          <div class="step">step2</div>
          <div class="dot-box">
            <div class="dot" v-for="i in 7" :key="i"/>
          </div>
        </div>
        <img src="../assets/mobile/images/s3-2.png" class="img1">
        <div class="line3">配置小程序功能排版</div>
      </swiper-slide>
      <swiper-slide>
        <div class="line1">
          快速搭建
          <span>
            在线化工具
            <img src="../assets/mobile/images/xcx.png" class="tip"/>
          </span>
        </div>
        <div class="line2">
          <p>助你实现</p>
          <p>线上化转型</p>
        </div>
        <div class="step-box">
          <div class="dot-box">
            <div class="dot" v-for="i in 7" :key="i"/>
          </div>
          <div class="step">step3</div>
          <div class="dot-box" style="visibility: hidden;">
            <div class="dot" v-for="i in 7" :key="i"/>
          </div>
        </div>
        <img src="../assets/mobile/images/s3-3.png" class="img1">
        <div class="line3">小程序上线</div>
      </swiper-slide>
    </swiper>
    <img src="../assets/mobile/images/1360.png" class="down-bar"/>
  </div>
</template>

<script>
  import SwiperCore, { Pagination, Autoplay } from 'swiper';
  import { Swiper, SwiperSlide } from 'swiper/vue';
  import "swiper/css/pagination"
  import "swiper/css/autoplay"

  SwiperCore.use([Pagination, Autoplay])
  export default {
    name: 'Slide3',
    data() {
      return {
        pagination: {
          "clickable": true,
          bulletClass : 'bullet',
          bulletActiveClass: 'bullet-active'
        }
      }
    },
    components: {
      Swiper,
      SwiperSlide,
    },
    setup() {
      return {
        modules: [ Pagination, Autoplay ],
      }
      
    }
  }
</script>
<style scoped>
.slide {
  position: relative;
  width: 100%;
  height: 100%;
  background: #FFF;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.sub-swiper {
  width: 100%;
  height: 100%;
  flex: 1 1 auto;
}
.swiper-slide {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  color: #3866BC;
  flex-direction: column;
}

.line1 {
  font-size: 24px;
  line-height: 50px;
  font-weight: 400;
  margin-top: 100px;
}
.line1 span {
  position: relative;
  color: #07C160;
}
.line1 span .tip {
  position: absolute;
  top: -30px;
  right: -16px;
  width: 66px;
  height: auto;
}

.line2 {
  font-weight: 700;
  font-size: 40px;
  line-height: 50px;
  text-align: center;
}

.step-box {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 44px 0 39px;
}

.step-box .step {
  flex: 0 0 99px;
  background: #F17E55;
  border-radius: 50px;
  font-weight: bold;
  font-size: 24px;
  line-height: 43px;
  color: #FFF;
  width: 99px;
  height: 43px;
  text-align: center;
}
.step-box .dot-box {
  flex: 2;
  display: flex;
  justify-content: space-between;
  padding: 0 12px;
}
.step-box .dot-box .dot {
  background: #FFAE92;
  width: 11px;
  height: 11px;
  border-radius: 100%;
}

.img1 {
  height: 220px;
  width: auto;
}

.line3 {
  font-size: 16px;
  line-height: 38px;
  color: #8D8E94;
  margin-bottom: 42px;
}
</style>
