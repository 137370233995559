<template>
  <div class="slid6">
    <div class="slide">
      <div class="line1">丰富多样的内容形式</div>
      <div class="line2">
        <p>助你建立更精准</p>
        <p>的用户画像</p>
      </div>
      <div class="img-box">
        <img src="../assets/mobile/images/s6.png" class="img1">
      </div>
    </div>
    <img src="../assets/mobile/images/1361.png" class="down-bar"/>
  </div>
</template>

<script>
  export default {
    name: 'Slide4',
  }
</script>
<style scoped>
.slid6 {
  width: 100%;
  height: 100%;
  color: #FFFFFF;
  background-color: #3866BC;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.slide {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  flex: 1 1 auto;
}

.line1 {
  text-align: center;
  font-size: 24px;
  line-height: 50px;
  font-weight: 400;
}

.line2 {
  text-align: center;
  font-weight: 700;
  font-size: 40px;
  line-height: 50px;
  text-align: center;
}

.img-box {
  flex: 0;
  height: 254.56px;
  width: 100%;
  margin-top: 103px;
}

.img1 {
  height: 100%;
  width: auto;
}

.line3 {
  font-weight: bold;
  font-size: 24px;
  line-height: 38px;
  color: #F17E55;
  margin: 53px auto 24px;
}
</style>
